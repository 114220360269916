<template>
    <div class="page">
        <div class="flex between" style="margin-bottom: 24px;">
            <h4>{{$t('redirect.list')}}</h4>
            <div style="text-align: right;">
                <a-button type="primary" style="margin-right: 24px;" @click="$router.push({name:'redirect-add'})">
                    {{$t('redirect.create')}}
                </a-button>
                <a-button @click="del(selectedRows)" :disabled="selectedRows.length===0">{{$t('redirect.del')}}
                </a-button>
            </div>
        </div>
        <a-table rowKey="id" :scroll="{ x:1000, y: 400 }" :columns="columns" :data-source="data"
                 :pagination="pagination" :row-selection="rowSelection">
            <template #name="{record}">
                <a @click="()=>{detailInfo(record)}">{{record.name}}</a>
            </template>
            <template #scope="{record}">
              <span v-if="record.scope==='GLOBAL'">{{$t('DOMAIN')}}</span>
              <div v-if="record.scopeName">
                <span v-if="record.scope==='SPECIFY_GROUP'" :title="record.scopeName">{{$t('GROUP')}} [ {{record.scopeName.length>6?record.scopeName.slice(0,6)+'...':record.scopeName}} ]</span>
                <span v-if="record.scope==='SPECIFY_DEVICE'" :title="record.scopeName">{{$t('DEVICE')}} [ {{record.scopeName.length>6?record.scopeName.slice(0,6)+'...':record.scopeName}} ]</span>
              </div>
              <div v-if="!record.scopeName">-</div>
            </template>
            <template #state="{record}">
                <a-badge v-if="record.state" status="success" :text="$t('enable')"/>
                <a-badge v-else status="error" :text="$t('disabled')"/>
            </template>

            <template #action="{record}">
                <a @click="$router.push({name:'redirect-edit',query:{name:record.name,id:record.id}})">{{$t('edit')}}</a>
                <a-divider type="vertical"/>
                <a-popconfirm
                        :title="record.state?$t('redirect.disabledPlz'):$t('redirect.enabledPlz')"
                        :ok-text="$t('ok')"
                        :cancel-text="$t('cancel')"
                        @confirm="rowActionStateFunc(record)">
                    <a v-if="record.state">{{$t('disabled')}}</a>
                    <a v-else>{{$t('enable')}}</a>
                </a-popconfirm>
                <a-divider type="vertical"/>
                <a-popconfirm
                        :title="$t('redirect.del')"
                        :ok-text="$t('ok')"
                        :cancel-text="$t('cancel')"
                        @confirm="delRow(record)">
                    <a>{{$t('del')}}</a>
                </a-popconfirm>
            </template>
        </a-table>
        <info :info="redirectInfo.info" :show="redirectInfo.show" @close="redirectInfo.show=false"/>
    </div>
</template>

<script>
    import i18n from "../../../../locale/main";
    import {reactive, createVNode, ref, computed} from 'vue'
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
    import {Modal, message} from 'ant-design-vue';
    import {useStore} from 'vuex';
    import Info from "./info";
    import {changeStatus, remove,getDetail} from '../../../../service/redirect'

    export default {
        components: {Info},
        setup() {
            let t = i18n.global.t
            let store = useStore()
            const selectedRows = ref([])

            const redirectInfo = reactive({
                show: false,
                info: {},
            })
            const columns = [
                {
                    title: t('redirect.name'),
                    width: 300,
                    slots: {customRender: 'name'},
                },
                {
                    title: t('redirect.range'),
                    width: 200,
                    slots: {customRender: 'scope'}
                },
                {title: t('device.userAttr'), width: 140, dataIndex: 'user', ellipsis: true,},
                {title: t('createTime'), width: 200, dataIndex: 'createTime', ellipsis: true,},
                {
                    title: t('redirect.state'),
                    slots: {customRender: 'state'},
                    width: 100,
                },
                {
                    title: t('action'),
                    fixed: 'right',
                    width: 200,
                    slots: {customRender: 'action'},
                },
            ].map((r, k) => {
                r.key = k;
                return r
            });

            const rowSelection = reactive({
                selectedRowKeys: [],
                onChange: (selectedRowKeys, rows) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    selectedRows.value = rows
                },
            });
            const pagination = reactive({
                current: computed(() => store.state.redirect.current),
                pageSize: computed(() => store.state.redirect.size),
                pageSizeOptions: store.state.redirect.sizeAll,
                total: computed(() => store.state.redirect.total),
                showSizeChanger: true,
                showTotal: (total) => t('pages.total', {num: total}),
                async onChange(val) {
                    await store.dispatch('redirect/search', {
                        current: val,
                        size: store.state.user.size,
                    })
                },
                async onShowSizeChange(_, size) {
                    await store.dispatch('redirect/search', {
                        current: 1,
                        size: size,
                    })
                },
            })
            return {
                redirectInfo,
                del(rows = []) {
                    Modal.confirm({
                        title: t('redirect.del'),
                        icon: createVNode(ExclamationCircleOutlined),
                        content: t('redirect.delPlz'),
                        okText: t('ok'),

                        async onOk() {
                            await remove({ids: rows.map(r => r.id)})
                            rowSelection.selectedRowKeys = []
                            await store.dispatch('redirect/search', {current: 1,})
                            message.success(t('delOk'))
                        },
                        cancelText: t('cancel'),
                    });
                },
                async delRow(row) {
                    await remove({ids: [row.id]})

                    await store.dispatch('redirect/search', {current: 1,})
                    message.success(t('delOk'))
                },
                async rowActionStateFunc(record) {
                    await changeStatus({id: record.id, status: !record.state})
                    await store.dispatch('redirect/search', {current: 1,})
                    message.success(t('actionOk'))
                },
                rowSelection,
                selectedRows,
                columns,
                pagination,
                data: computed(() => store.state.redirect.data),
                async detailInfo(record){
                    let rs=await getDetail({id:record.id})
                    let info=rs.data.result
                    redirectInfo.info={
                        ...info.redirect,
                        user:record.username,
                        desc:info.redirect.description,
                        createTime:info.redirect.createAt,
                        state:info.redirect.ifEnable,
                        provision:{
                            url:info.provision.url,
                            login:info.provision.username,
                        },
                        strategies:info.redirectServerStrategys,
                        redirectMetadataList:info.redirectMetadataList,
                        devices:info.devices,
                    };
                    redirectInfo.show=true;
                }
            }
        }
    }
</script>

<style scoped>

</style>