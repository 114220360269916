<template>
    <a-drawer width="660" placement="right" :closable="false" :visible="show" @close="onClose">
        <div class="info-box">
            <div class="flex left title">
                <h2>{{info.name}}</h2>
                <a-button v-if="!info.state"  style="margin-right: 10px;" danger  ghost>{{$t('redirect.disable')}}</a-button>
                <a-button  v-if="info.state" style="margin-right: 10px;" type="primary" ghost>{{$t('redirect.enable')}}</a-button>
            </div>
            <div class="flex left align-left content">
                <div class="flex left item" style="width: 50%;">
                    <div class="label">{{$t('device.userAttr')}}</div>
                    <div class="val">{{info.user}}</div>
                </div>
                <div class="flex left item" style="width: 50%;">
                    <div class="label">{{$t('createTime')}}</div>
                    <div class="val">{{info.createTime}}</div>
                </div>
                <div class="flex left item" style="width: 50%;">
                    <div class="label">{{$t('redirect.range')}}</div>
                    <div class="val">
                        <span v-if="info.scope==='GLOBAL'">{{$t('DOMAIN')}}</span>
                        <span v-if="info.scope==='SPECIFY_GROUP'">{{$t('GROUP')}}</span>
                        <span v-if="info.scope==='SPECIFY_DEVICE'">{{$t('DEVICE')}}</span>
                    </div>
                </div>
                <div class="flex left align-left item">
                    <div class="label">{{$t('redirect.desc')}}</div>
                    <div class="val" style="width: 478px;">{{info.desc||'-'}}</div>
                </div>
            </div>
        </div>
        <div class="info-box">
            <div class="title">
                <h4>{{$t('redirect.server')}}</h4>
            </div>
            <div class="flex between align-left content">
                <div  v-if="info?.strategies?.find(r=>r.aim==='NMS')" class="flex left item" >
                    <div class="label">{{$t('redirect.service',{name:'NMS'})}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='NMS').ip}}</div>
                    <div class="label" >{{$t('port')}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='NMS').port}}</div>
                </div>


                <div v-if="info?.strategies?.find(r=>r.aim==='DRP')" class="flex left item">
                    <div class="label">{{$t('redirect.service',{name:'DRP'})}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='DRP').ip}}</div>
                    <div class="label">{{$t('port')}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='DRP').port}}</div>
                </div>

                <div  v-if="info?.strategies?.find(r=>r.aim==='DMCLD')" class="flex left item">
                    <div class="label">{{$t('redirect.service',{name:'DMCLD'})}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='DMCLD').ip}}</div>
                    <div class="label">{{$t('port')}}</div>
                    <div class="val">{{info.strategies.find(r=>r.aim==='DMCLD').port}}</div>
                </div>
            </div>
            <div class="info-box">
                <div class="flex left title">
                    <h4>PROVISION</h4>
                </div>
                <div class="flex left align-left content">
                    <div  class="flex left align-left item">
                        <div class="label">{{$t('redirect.url')}}</div>
                        <div class="val">{{info?.provision?.url||'-'}}</div>
                    </div>
                    <div class="flex left item">
                        <div class="label">{{$t('redirect.login')}}</div>
                        <div class="val">{{info?.provision?.login||'-'}}</div>
                    </div>
                    <div  class="flex left item">
                        <div class="label">{{$t('redirect.pwd')}}</div>
                        <div class="val">{{info?.provision?.pwd||'-'}}</div>
                    </div>
                   <!-- <div v-if="info?.provision?.auth" class="flex left item">
                        <div class="label">{{$t('redirect.auth')}}</div>
                        <div class="val">
                            <a :href="info?.provision?.auth">{{info?.provision?.authName}}</a>
                        </div>
                    </div>-->
                </div>
            </div>
            <div class="info-box">
                <div class="flex left title">
                    <h4>{{$t('redirect.device')}}</h4>
                </div>
                <div v-if="info.scope==='GLOBAL'" class="flex left align-left content">
                    <div class="flex left item">
                        <div class="label">{{$t('redirect.range')}}</div>
                        <div class="val">
                            <span v-if="info.scope==='GLOBAL'">{{$t('DOMAIN')}}</span>
                        </div>
                    </div>
                </div>
                <a-table
                        v-if="info.scope==='SPECIFY_GROUP'"
                        row-key="deviceSn"
                        :dataSource="device.list"
                        :pagination="device.pagination"
                        :columns="columns"
                        :scroll="{ x:true, y: 500 }"/>
                <a-table
                        v-if="info.scope==='SPECIFY_DEVICE'"
                        row-key="deviceSn"
                        :dataSource="device.list"
                        :columns="columns"
                        :scroll="{ x:true, y: 500 }"/>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import i18n from "../../../../locale/main";
    import { reactive , watch} from 'vue'
    import {toPage} from '../../../../service/device'
    export default {
        emits: ['close'],
        components: {
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default: null,
            }
        },
        setup(props,ctx){
            let t = i18n.global.t
            let device=reactive({
                list:[],
                async getDeviceByGroupId(page,size){
                    let rs=await toPage({
                        groupId:props.info.redirectMetadataList[0].scopeValue,
                        cursor:page,
                        limit:size,
                    })
                    device.list= rs.data.result.records
                    device.pagination.total=  rs.data.result.total
                },
                pagination:{
                    current: 1,
                    pageSize: 10,
                    pageSizeOptions: ['10', '20', '30'],
                    total: 0,
                    showSizeChanger: true,
                    showTotal: (total) => t('pages.total', {num: total}),
                    async onChange(val) {
                        await device.getDeviceByGroupId(val,device.pagination.pageSize)
                    },
                    async onShowSizeChange(_, size) {
                        await device.getDeviceByGroupId(device.pagination.current,size)
                    },
                }
            })
            watch(()=>props.show,async ()=>{
                if(props.show){
                    if(props.info.scope==='SPECIFY_GROUP'){
                        device.pagination.current=1
                        await device.getDeviceByGroupId(device.pagination.current,device.pagination.pageSize)
                    }
                    if(props.info.scope==='SPECIFY_DEVICE'){
                        device.list=props.info.devices
                    }
                }
            })
            return {
                device,
                columns:[
                    {title: t('device.sn'), width: 200, dataIndex: 'deviceSn',ellipsis:true,},
                    {title: t('device.mac'), width: 200, dataIndex: 'mac',ellipsis:true,},
                ],
                onClose(){
                    ctx.emit('close',true)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-box{
        .title{
            margin: 30px 0;
        }
        h2,h4{
            margin-right: 10px;
        }
        .label,.val{
            color: rgba(0,0,0,0.5);
            font-size: 14px;
        }
        .label{
            width: 120px;
            text-align: right;
            &:after{
                content: ':';
                display: inline-block;
                margin: 0 2px;
            }
        }
        .val{
            margin-left: 4px;
            max-width: 70%;
            word-break: break-all;
        }
        .item{
            width: 100%;
            margin-bottom: 10px;
        }
    }
</style>