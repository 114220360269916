<template>
   <list-pge-view>
      <template #search-view>
         <search/>
      </template>
      <template #page-view>
         <page/>
      </template>
   </list-pge-view>
</template>

<script>
   import listPgeView from "../../../../layout/listPageView";
   import search from './search';
   import page from './page';
    export default {
        components:{
           listPgeView,
           search,
           page,
        }
    }
</script>

<style scoped>

</style>