<template>
    <a-row :gutter="[48,24]" class="search-form">
        <a-col  :sm="10" :xxl="8">
            <a-form-item :label="$t('redirect.search')" :colon="false">
                <a-input-search allowClear v-model:value="formState.search" @search="formState.searchHandle" :placeholder="$t('redirect.searchPzl')" />
            </a-form-item>
        </a-col>
        <a-col :sm="7" :xxl="4" v-if="ifAdmin">
            <a-form-item :label="$t('users')" :colon="false">
                <a-select
                        showSearch
                        allowClear
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        v-model:value="formState.users"
                        :filter-option="false"
                        :options="usersData"
                        @search="formState.searchUser"
                        @change="formState.searchHandle"/>
            </a-form-item>
        </a-col>
        <a-col :sm="7" :xxl="4">
            <a-form-item :label="$t('redirect.range')" :colon="false">
                <a-select
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        v-model:value="formState.range"
                        @change="formState.searchHandle">
                    <a-select-option value="GLOBAL">{{$t('DOMAIN')}}</a-select-option>
                    <a-select-option value="SPECIFY_GROUP">{{$t('GROUP')}}</a-select-option>
                    <a-select-option value="SPECIFY_DEVICE">{{$t('DEVICE')}}</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :sm="ifAdmin?6:7" :xxl="4" >
            <a-form-item :label="$t('redirect.state')" :colon="false" >
                <a-select
                        showSearch
                        :placeholder="$t('plz.select')"
                        style="width: 100%;"
                        v-model:value="formState.state"
                        @change="formState.searchHandle">
                    <a-select-option value="true">{{$t('enable')}}</a-select-option>
                    <a-select-option value="false">{{$t('disabled')}}</a-select-option>
                </a-select>
            </a-form-item>
        </a-col>
        <a-col :sm="ifAdmin?18:24" :xxl="ifAdmin?4:8"  class="flex right">
            <a-button @click="formState.reset">{{$t('reset')}}</a-button>
        </a-col>
    </a-row>
</template>

<script>
    import {reactive, toRaw, computed, onMounted} from 'vue'
    import {useStore} from "vuex";
    export default {
        setup(){
            let store = useStore()
            let usersData= computed(() => {
                return store.state.user.data.map(r => {
                    r.label = r.username
                    r.value = r.id
                    return r
                })
            })
            let formState=reactive({
                search:null,
                state:null,
                users:null,
                range:null,
                searchHandle(){
                    store.dispatch('redirect/search',{searchForm:toRaw(formState),current:1,search:true})
                },
                searchUser(val){
                    store.dispatch('user/search',{searchForm:{search:val},current:1,search:true})
                },
                reset(){
                    formState.search=null
                    formState.state=null
                    formState.users=null
                    formState.range=null
                    store.dispatch('redirect/search',{searchForm:toRaw(formState),current:1,search:true})
                }
            })
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)
            onMounted(async()=>{
                if(ifAdmin.value){
                    await store.dispatch('user/search', {searchForm: {search: ''}, current: 1, search: true})
                }

                formState.searchHandle()
            })
            return {
                ifAdmin,
                formState,
                usersData,
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-form{
    ::v-deep(.ant-form-item){
        margin-bottom: 0;
    }
}
</style>